"use client"
import React, { useContext, useEffect, useMemo } from 'react';
import mixpanel from 'mixpanel-browser'
import Link from 'next/link';
import { GoogleAnalytics } from '@next/third-parties/google'
import { sendGAEvent } from '@next/third-parties/google'

export enum EventType {
  VIEW_COMPONENT = "view_component",
  CLICK_BUTTON = "click_button",
  CLICK_LINK = "click_link",
  SUBMIT_FORM = "submit_form",
  PAYMENT_ERROR = "payment_error",
}

export interface AnalyticsContext {
  track(eventType: EventType, properties?: Record<string, any>): void;
}

class DebugAnalyticsContext implements AnalyticsContext {
  constructur() {
    console.log(`Initializing Mixpanel with token: ${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}`);
  }

  track(eventType: EventType, properties?: Record<string, any>) {
    console.log(`Tracking event: ${eventType}, Properties: ${JSON.stringify(properties)}`);
  }
}

class ProductionAnalyticsContext implements AnalyticsContext {
  constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      track_pageview: false,
    });
  }

  track(eventType: EventType, properties?: Record<string, any>) {
    mixpanel.track(eventType, properties);
    sendGAEvent({ event: eventType, ...properties })
  }
}

const analyticsContext = React.createContext<AnalyticsContext | undefined>(undefined);

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {

  const context: AnalyticsContext = useMemo(() => {
    const isProd = process.env.NODE_ENV === "production";

    if (isProd) {
      return new ProductionAnalyticsContext()
    }

    return new DebugAnalyticsContext()
  }, [])

  return (
    <>
      <analyticsContext.Provider value={context}>
        {children}
      </analyticsContext.Provider>
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_TRACKING_ID!} />
    </>
  )
}

export const useAnalytics = () => {
  const context = useContext(analyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};

interface TrackComponentProps {
  children?: React.ReactNode
  componentName: string
  trackingProps?: Record<string, any>;
}
export const TrackComponent = ({ children, componentName, trackingProps }: TrackComponentProps) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.track(EventType.VIEW_COMPONENT, { "component": componentName, ...trackingProps  });
  })

  return <>{children}</>;
};

interface TrackedLinkProps extends React.ComponentProps<typeof Link> {
  children: React.ReactNode;
  trackingProps?: Record<string, any>;
}

export const TrackedLink = React.forwardRef<HTMLAnchorElement, TrackedLinkProps>(
  ({ children, trackingProps, onClick, ...rest }, ref) => {
    const analytics = useAnalytics();  // Make sure useAnalytics is defined or imported correctly

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      analytics.track(EventType.CLICK_LINK, { "url": rest.href, ...trackingProps });
      if (onClick) {
        onClick(event);
      }
    };

    return <Link {...rest} ref={ref} onClick={handleClick}>{children}</Link>;
  }
);

// Set the display name for the component
TrackedLink.displayName = 'TrackedLink';

