import Hero from "./hero/hero";
import Bids from "./bids/bids.jsx";
import Top_collection from "./collectrions/top_collection.jsx";
import Tranding_category from "./categories/tranding_categories.jsx";
import HeadLine from "./headLine";
import Newsletter from "./blog/newsletter.jsx";
import Auctions_categories from "./categories/Auctions_categories.jsx";
import Feature_collections from "./collectrions/Feature_collections.jsx";
import Partners from "./partners/Partners.jsx";
import Browse_category from "./categories/Browse_category.jsx";
import Hero_3 from "./hero/hero_3.jsx";
import ItemsTabs from "./tabs/Tabs.jsx";

export {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  HeadLine,
  Newsletter,
  Auctions_categories,
  Feature_collections,
  Partners,
  Browse_category,
  Hero_3,
  ItemsTabs,
};
