"use client"
import Image from "next/image"
import fetchJsonp from "fetch-jsonp"
import { useForm } from "react-hook-form"
import { useState } from "react"

const MAILCHIMP_URL = "https://gmail.us21.list-manage.com/subscribe/post-json"

interface SubscriptionFormValues {
  email?: string
  validationField?: string
}

const SubscriptionForm = ({ bgWhite = true }) => {
    const [submissionResult, setSubmissionResult] = useState("")

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isSubmitted, isDirty },
  } = useForm<SubscriptionFormValues>({
    defaultValues: {
        email: '',
        validationField: ''
    }
  })

  const onSubmit = handleSubmit(async (values: SubscriptionFormValues) => {
    const queryString = new URLSearchParams({
        u: "4ced864b9945037a247b0e679",
        id: "2639394eef",
        f_id: "00b0f9e6f0",
        EMAIL: values.email || "",
        b_4ced864b9945037a247b0e679_2639394eef: values.validationField || ""
      })

        fetchJsonp(`${MAILCHIMP_URL}?${queryString}`, { jsonpCallback: 'c', timeout: 3000 }).then(res => {
            if (res.ok) {
                setSubmissionResult("מעולה, רשמתי אותך! 🥳 אעדכן כשתעלה יצירה חדשה");
                reset()
            } else {
                setSubmissionResult("אופס, משהו השתבש 😵‍💫 אני על זה, שננסה מאוחר יותר?");
            }
        }).catch(e => {
            console.error(e)
            setSubmissionResult("אופס, משהו השתבש 🥵 אני על זה, שננסה מאוחר יותר?");
        })
    })

  return (
    <section className="dark:bg-jacarta-800 relative py-24">
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
            width={1559}
            height={761}
          />
        </picture>
      )}

<div className="container">
        <p className="text-jacarta-700 mx-auto mt-10 max-w-2xl text-center text-lg dark:text-white">
          הצטרפו לרשימת התפוצה שלנו וקבלו עדכון בכל פעם שעולה יצירה חדשה לאתר!
        </p>

        <div className="mx-auto mt-7 max-w-xl text-center">
            <form className="relative" onSubmit={onSubmit}>
            <button disabled={isSubmitting} type="submit" className="hover:bg-accent-dark font-semibold bg-accent absolute top-2 left-2 rounded-full px-6 py-2 text-sm text-white">
              תרשום אותי אבל אל תציק אה?
            </button>
            <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <input type="text" {...register('validationField')} name="b_4ced864b9945037a247b0e679_2639394eef" tabIndex={-1} value="" />
            </div>
            <input
              {...register("email", { required: "בלי כתובת מייל תקינה לא אוכל לרשום אותך 🥺", pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "בלי כתובת מייל תקינה לא אוכל לרשום אותך 🥺"
              }})}
              type="email"
              id="mce-EMAIL"
              placeholder="youremail@gmail.com"
              className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            />
            <div className="mt-6">
                {errors.email ? errors.email?.message : undefined}
                {isDirty && !isSubmitted ? undefined : submissionResult}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionForm
