const baseUrl = `${process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL}/store`

export const callMedusaAPI = async (path: string) => {
  const response = await fetch(`${baseUrl}/${path}`)

  if (!response.ok) {
    throw new Error(`Failed to fetch from /${path}`)
  }

  return await response.json()
}

export const getFeaturedProduct = async () => {
  return await callMedusaAPI(`featured_product`)
}
