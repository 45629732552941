import { useEffect, useState } from "react"
import { getFeaturedProduct } from "./medusa-api"

interface FeaturedProductHookResult {
    featuredProduct: any
    loading: boolean
    error: Error | null
}

export const useFeaturedProduct = (): FeaturedProductHookResult => {
    const [featuredProduct, setFeaturedProduct] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {                
                const result = await getFeaturedProduct()
                setFeaturedProduct(result)
            } catch (e) {
                setError(e as Error);
            } finally {
                setLoading(false)
            }
        }
        
        fetchData()
    }, [])
    
    return {
        featuredProduct,
        loading,
        error
    }
}