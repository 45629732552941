import Image from "next/image";

const Newsletter = ({ bgWhite = true }) => {
  return (
    <section className="dark:bg-jacarta-800 relative py-24">
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
            width={1559}
            height={761}
          />
        </picture>
      )}

      <div className="container">
        <p className="text-jacarta-700 mx-auto mt-10 max-w-2xl text-center text-lg dark:text-white">
          הצטרפו לרשימת התפוצה שלנו וקבלו עדכון בכל פעם שעולה יצירה חדשה לאתר!
        </p>

        <div className="mx-auto mt-7 max-w-xl text-center">
          <form className="relative">
          <button className="hover:bg-accent-dark font-semibold bg-accent absolute top-2 left-2 rounded-full px-6 py-2 text-sm text-white">
              תרשום אותי אבל אל תציק אה?
            </button>
            <input
              type="email"
              placeholder="youremail@gmail.com"
              className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
