import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/bids/bids.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/carousel/Browse_category_carousel.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/carousel/Feature_collection_carousel.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/categories/Auctions_categories.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/categories/trending_categories_items.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/collectrions/top_collection.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/featured-product/featured-product.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/hero/hero_3.jsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/hero/hero.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/subscription-form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/tabs/Tabs.jsx")