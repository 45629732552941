"use client"

import React, { useEffect, useMemo, useState } from "react"
import Link from "next/link"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Thumbs, Autoplay, Pagination } from "swiper"
import SwiperCore from "swiper";
import Image from "next/image"
// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import { useFeaturedProduct } from "@lib/use-featured-product-hook"
import Countdown, { CountdownRenderProps, CountdownRendererFn } from "react-countdown"
import { TrackedLink, EventType, useAnalytics } from "@lib/analytics"
import { useRouter } from 'next/navigation'
import { addToCart } from "@lib/actions"
import { Region } from "@medusajs/medusa/dist/models/region"


const CountdownRenderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (!completed) {
    return (
      <div className="js-countdown-single-timer mb-4">
        <span className="block text mb-2 font-medium tracking-tight">
          כדאי להזדרז, היצירה הבאה תעלה בעוד
        </span>
        <span className="text-lg font-medium lg:text-[1.5rem]">
          {hours > 0 ? `${String(hours).padStart(2, '0')}:` : undefined}
          {minutes > 0 ? `${String(minutes).padStart(2, '0')}:` : undefined}
          {String(seconds).padStart(2, '0')} {hours > 0 ? 'שעות' : (minutes > 0 ? 'דקות' : 'שניות')}
        </span>
      </div>
    );
  }
};

const FeaturedProduct = ({ region }: { region?: Region | null | undefined }) => {
  const {
    featuredProduct: { featuredProduct, until, isFeatured },
    loading,
    error,
  } = useFeaturedProduct()
  const analytics = useAnalytics();
  const router = useRouter()

  const handleBuyNow = useMemo(() => async (featuredProduct: any) => {
    if (!featuredProduct || !region) return
    
    const cart = await addToCart({ variantId: featuredProduct.variant_id, quantity: 1, regionId: region.id, buyNow: true })
    // @ts-ignore
    analytics.track(EventType.CLICK_LINK, { "to": "checkout_page", "component": "featured_product_widget", "cartId": cart.id });
    router.push(`/checkout`)
  }, [analytics, router, region])

  const [activeThumb, setActiveThumb] = useState<SwiperCore | null>(null);

  if (loading || error || !featuredProduct) {
    return <></>
  }

  return (
    <>
      {/* <!-- Hero Slider --> */}
      <section className="relative h-screen" id="featured">
        <Swiper
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          loop={featuredProduct?.images?.length > 1}
          spaceBetween={0}
          navigation={true}
          thumbs={{
            swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          className="full-slider h-screen"
        >
          <div className="w-full absolute top-0 z-10 pt-40 text-center">
            <h2>
              <span className="text-3xl text-[#F35BC7] font-semibold bg-black/60 py-1 lg:py-2 px-2 lg:px-3 rounded-md">{featuredProduct.title}</span>
            </h2>
            <div className="text-small font-medium mt-3.5">
              <span className="text-white bg-black/60 py-1 lg:py-2 px-2 lg:px-3 rounded-md lg:rounded-b-md">{featuredProduct.subtitle}</span>
            </div>
          </div>

          <div className="w-full absolute bottom-0 z-10 pb-28 text-center">
            <div className="pt-5 pb-5 text-white rounded-lg bg-black/60 w-72 lg:w-80 mx-auto">
              {
                isFeatured ? (<Countdown date={new Date(until)} renderer={CountdownRenderer}></Countdown>) : (
                  <div className="mb-4 text font-medium tracking-tight">
                    היצירה הבאה תעלה בקרוב, בינתיים תוכלו לנסות את אחת היצירות המצליחות שלנו 
                  </div>
                )
              }
              <button onClick={() => handleBuyNow(featuredProduct)} className="hover:bg-accent-dark font-semibold bg-accent rounded-full px-6 py-2 text-white">
                    אני רוצה את זה!
              </button>
            </div>
          </div>

          {featuredProduct?.images?.map((image: { id: string, url: string }, index: number) => {
            const { id, url } = image

            return (
              <SwiperSlide key={id}>
                <div className="swiper-slide">
                  <Image
                    width={1519}
                    height={722}
                    src={url}
                    className="absolute inset-0 h-full w-full object-contain bg-black"
                    alt={`${featuredProduct.title} - Image ${index + 1}`}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>

        {/* <!-- Thumbs --> */}
        {featuredProduct?.images?.length > 1 ? (
          <div className="absolute inset-x-0 bottom-12">
            <div className="container">
              {/* <Thumbs_carousel /> */}
              <Swiper
                modules={[FreeMode, Navigation, Thumbs]}
                onSwiper={setActiveThumb}
                loop={false}
                spaceBetween={10}
                breakpoints={{
                  100: {
                    slidesPerView: Math.min(10, featuredProduct.images.length),
                  },
                  768: {
                    slidesPerView: Math.min(10, featuredProduct.images.length),
                  },
                }}
                className="full-slider-thumbs swiper-initialized swiper-horizontal swiper-pointer-events swiper-thumbs"
              >
                {featuredProduct.images.map((image: { id: string, url: string }, index: number) => {
                  const { url, id } = image
                  return (
                    <SwiperSlide
                      className="swiper-slide p-5 swiper-slide-duplicate swiper-slide-duplicate-next "
                      key={id}
                    >
                      {/* <Image
                        width={343}
                        height={188}
                        src={url}
                        className="w-full h-full object-cover rounded-lg"
                        alt={`${featuredProduct.title} - Image ${index + 1}`}
                      /> */}
                      <div className="carousel-progress relative -bottom-5 z-10 -ml-5 -mr-5 h-0.5 bg-white/30 ">
                        <div className="progress bg-accent absolute h-0.5 w-0"></div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        ) : undefined}
      </section>
    </>
  )
}

export default FeaturedProduct
