"use client"

import { TrackComponent, TrackedLink } from "@lib/analytics";
import Image from "next/image";
import { useEffect, useRef } from "react";

const Hero = () => {
  const linkToFeaturedRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      
      const href = linkToFeaturedRef.current?.getAttribute('href');
      if (href && href.startsWith('#') && href.length > 1) {
        const targetId = href.substring(1)
        const targetElement = document.getElementById(targetId)

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' })
        } else {
          console.error(`Element with ID '${targetId}' not found.`)
        }
      } else {
        console.error(`Invalid 'href' attribute: '${href}'. It must start with '#' and include an ID.`);
      }
    };

    const link = linkToFeaturedRef.current;
    if (link) {
      // @ts-ignore
      link.addEventListener('click', handleClick)
    }

    return () => {
      if (link) {
        // @ts-ignore
        link.removeEventListener('click', handleClick);
      }
    };
  }, [linkToFeaturedRef]);

  return (
    <section id="hero" className="relative pb-20 pt-20 md:pt-32 h-1527">
      <TrackComponent componentName="main_page_hero" />
      
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block h-full">
        <Image
          width={1519}
          height={760}
          priority
          src="/images/gradient-2.jpg"
          alt="gradient"
          className="h-full w-full object-cover"
        />
      </picture>
      
      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="animate-gradient font-extrabold mb-6 text-center text-5xl dark:text-white md:text-right lg:text-6xl xl:text-7xl">
               אומנות אחת ביום
            </h1>
            <p className="font-thin mb-8 text-center text-lg md:text-right leading-7">
              <span className="font-semibold">גלו את היצירתיות שבכם.</span> בכל בוקר תעלה יצירה חדשה עם הוראות מלאות בעברית, לכם יש 24 שעות לרכוש אותן 🤗
            </p>
            <div className="flex space-x-4">
              <TrackedLink
                trackingProps={{"to": "view_featured", "component": "main_page_hero"}}
                href="#featured"
                ref={linkToFeaturedRef}
                className="bg-accent shadow-accent-volume hover:bg-accent-dark w-72 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              >
                תראה לי את היצירה היומית!
              </TrackedLink>
            </div>
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pr-8 md:text-left">
              <Image
                width={560}
                height={560}
                priority
                src="/images/hero-image.png"
                alt="hero"
                className="hero-img mt-8 inline-block w-72 rotate-[10deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
              />
              <Image
                width={740}
                height={602}
                priority
                src="/images/hero/3D_elements.png"
                alt="floating image"
                className="animate-fly absolute top-0 md:-left-[10%] "
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
